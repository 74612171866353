#my-journey {
  @media screen and (min-width: 1024px) {
    height: 850px;
  }
}

.timeline-container {
  left: calc(50vw - 130px);

  @media screen and (min-width: 768px) {
    left: 38%;
  }

  @media screen and (min-width: 1024px) {
    left: 35%;
  }
}

.timeline {
  display: grid;
  grid-template-columns: repeat(6, 200px);
  grid-template-rows: auto auto;
  transition: left 0.6s ease-out;
}

.timeline-bar {
  left: 100px;
  width: 1000px;
  top: 50%;
  transform: translateY(-50%);
}

.journey-card-container {
  max-width: 600px;
  margin: 0 auto;
}
